import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link } from "react-router-dom";

function Navbar() {
    const [nav, setNav] = useState(false);

    const openNav = () => {
        setNav(!nav);
    };

    return (
        <div className="navbar-section">
            <h1 className="navbar-title">
                <Link to="/">
                    <img src="/assets/Images/Lifescape-Logo.png" alt="logo" width="200px" height="200px" />
                </Link>
            </h1>
            <ul className="navbar-items">
                <li>
                    <Link to="/" className="navbar-links">
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="/getstarted" className="navbar-links">
                        Get Started
                    </Link>
                </li>
            </ul>
            <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
                <h1 className="mobile-navbar-title">
                    <Link to="/">
                        <img src="/assets/Images/Lifescape-Logo.png" alt="logo" width="150px" />
                    </Link>
                    <div onClick={openNav} className="mobile-navbar-close">
                        <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
                    </div>
                </h1>
                <hr className="divider" />
                <div className="mobile-navbar-links">
                    <div>
                        <Link onClick={openNav} to="/">
                            Home
                        </Link>
                    </div>
                    <div>
                        <Link onClick={openNav} to="/getstarted">
                            Get Started
                        </Link>
                    </div>
                </div>
            </div>

            <div className="mobile-nav">
                <FontAwesomeIcon
                    icon={faBars}
                    onClick={openNav}
                    className="hamb-icon"
                />
            </div>
        </div>
    );
}

export default Navbar;
