import React from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/Welcome.css";

function Welcome() {
    const navigate = useNavigate();

    const handleApplyNow = () => {
        navigate("/getstarted");
    };

    return (
        <div className="section-container">
            <div className="welcome-section">
                <div className="text-section">
                    <h2 className="text-title">
                        Welcome to Lifescape
                    </h2>
                    <p className="text-descritpion">
                        Your gateway to a fulfilling nursing career in Germany! At Lifescape,
                        our mission is simple: to empower nurses like you to thrive professionally and personally in the vibrant healthcare landscape of Germany.
                        We understand the unique challenges and aspirations of nurses seeking opportunities abroad, and we're here to guide you every step of the way.
                        From navigating visa requirements to finding the perfect career match, Lifescape is your dedicated partner in realizing your dreams.
                        Join us on this exciting journey, where your skills meet limitless possibilities and where your nursing career takes flight.
                    </p>
                    <button
                        className="text-applynow-btn"
                        type="button"
                        onClick={handleApplyNow}
                    >
                        Apply Now
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Welcome;