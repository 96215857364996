import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Welcome from "../Components/Welcome";
import OurVision from "../Components/OurVision";
import Info from "../Components/Info";
import Advantage from "../Components/Advantage";
import Services from "../Components/Services";
import Footer from "../Components/Footer";
import "../Styles/Home.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

function Home() {
    const [goUp, setGoUp] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        const onPageScroll = () => {
            if (window.scrollY > 600) {
                setGoUp(true);
            } else {
                setGoUp(false);
            }
        };
        window.addEventListener("scroll", onPageScroll);

        return () => {
            window.removeEventListener("scroll", onPageScroll);
        };
    }, []);

    return (
        <div className="home-section">
            <Navbar />
            <Welcome />
            <OurVision />
            <Info />
            <Advantage />
            <Services />
            <Footer />
            <div
                onClick={scrollToTop}
                className={`scroll-up ${goUp ? "show-scroll" : ""}`}
            >
                <FontAwesomeIcon icon={faAngleUp} />
            </div>
        </div>
    );
}

export default Home;