import React from "react";
import Navbar from "../Components/Navbar";
import ApplicationForm from "../Components/ApplicationForm";
import Footer from "../Components/Footer";

function GetStarted() {
    return (
        <div className="home-section">
            <Navbar />
            <ApplicationForm />
            <Footer />
        </div>
    );
}

export default GetStarted;