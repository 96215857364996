import React from "react";
import Box from '@mui/material/Box';
import { Icon } from '@iconify-icon/react';
import "../Styles/Advantage.css";

function Advantage() {
    return (
        <div className="advatage-section">
            <Box
                sx={{
                    p: 5,
                    m: 5,
                    borderRadius: 5,
                    border: '3px solid #d7d3d3',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 5,
                    flexWrap: "wrap"
                }}
            >
                <div className="advantage-icon">
                    <Icon icon="ph:globe" style={{ color: "#4d4b4b", }} width="50px" height="50px" />
                    <p>Easy access to global talent pool</p>
                </div>
                <div className="advantage-icon">
                    <Icon icon="mdi:cash-multiple" style={{ color: "#4d4b4b" }} width="50px" height="50px" />
                    <p>Free of charge for applicants</p>
                </div>
                <div className="advantage-icon">
                    <Icon icon="iconoir:community" style={{ color: "#4d4b4b" }} width="50px" height="50px" />
                    <p>All-in-one solution for every step of the way</p>
                </div>
                <div className="advantage-icon">
                    <Icon icon="healthicons:nurse" style={{ color: "#4d4b4b" }} width="50px" height="50px" />
                    <p>Qualified nurses matched by algorithm</p>
                </div>
                <div className="advantage-icon">
                    <Icon icon="material-symbols:verified-outline" style={{ color: "#4d4b4b" }} width="50px" height="50px" />
                    <p>Verified & fast recruiting process</p>
                </div>
            </Box >
        </div >
    );
}

export default Advantage;