import React from "react";
import "../Styles/OurVision.css";

function OurVision() {

    return (
        <div className="vision-section">
            <div className="vision-text-section">
                <h2 className="vision-text-title">
                    Our Vision
                </h2>
                <p className="vision-text-descritpion">
                    At Lifescape, our vision goes beyond just simplifying the process of matching foreign healthcare professionals with opportunities in Germany.
                    We aspire to be recognized as the foremost platform in this domain, shaping a future of healthcare characterized by simplicity, individuality,
                    and speed. Our aim is to revolutionize the way nurses connect with career opportunities.
                </p>
            </div>
            <div className="vision-image-section">
                <img className="nurse-image1" src={'/assets/Images/Nurse.png'} alt="Nurse" />
            </div>
        </div>
    );
}

export default OurVision;