import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import "../Styles/Info.css";

function Info() {
    return (
        <div className="info-section">
            <div className="info-title-content">
                <h2 className="info-title">
                    <span>How We Work</span>
                </h2>
            </div>

            <div className="info-cards-content">
                <Card className="info-cards">
                    <CardContent>
                        <p className="info-card-title">Precision Matchmaking</p>
                        <p className="info-card-description">Our platform uses advanced algorithms to precisely pair nurses with compatible job openings in Germany</p>
                    </CardContent>
                </Card>
                <Card className="info-cards">
                    <CardContent>
                        <p className="info-card-title">Streamlined Recruitment</p>
                        <p className="info-card-description">Lifescape enhances every stage of the recruitment process, reducing vacancy duration</p>
                    </CardContent>
                </Card>
                <Card className="info-cards">
                    <CardContent>
                        <p className="info-card-title">Comprehensive Support</p>
                        <p className="info-card-description">We offer language and professional training solutions to improve employee retention and success</p>
                    </CardContent>
                </Card>

            </div>
        </div>
    );
}

export default Info;