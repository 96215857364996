import React, { useEffect, useMemo } from 'react'
import Select from 'react-select'
import { useForm } from "react-hook-form";
import "../Styles/ApplicationForm.css";
import countryList from 'react-select-country-list';
import emailjs from "@emailjs/browser";

function ApplicationForm() {
    const options = useMemo(() => countryList().getData(), [])
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm();

    useEffect(() => emailjs.init("wgfxrGroajdBtNx4M"), []);

    const onSubmit = async (data) => {
        const date = new Date(data?.dob);
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero if necessary
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month with leading zero if necessary
        const year = date.getFullYear();

        const formattedDoB = `${day}-${month}-${year}`;

        const emailParams = {
            applicant_name: data.firstName + ' ' + data.lastName,
            first_name: data.firstName,
            last_name: data.lastName,
            dob: formattedDoB,
            country: data.country,
            phone_number: data.phoneNumber,
            email: data.email,
        }

        const serviceId = "service_6gxdb8q";
        const templateId = "template_9ld03op";

        try {
            await emailjs.send(serviceId, templateId, emailParams);
            window.alert("Thanks for submitting the application.\nDetails has been sent to the admin team.\nAdmin team will contact you soon");
            // reset();
            window.location.reload();
        } catch (error) {
            window.alert("There is some technical issue.Please try again later...")
        }
    };

    const handleCountryChange = (selectedOption) => {
        setValue("country", selectedOption.label); // Update the form value for "country"
    };


    return (
        <div className="formContainer">
            <h2 className="formTitle">
                <span>Application Form</span>
            </h2>

            <form className="formContent" onSubmit={handleSubmit(onSubmit)}>
                <div className="formRow">
                    <label htmlFor="firstName" className="formLabel">First Name</label>
                    <div>
                        <input className="formValue"
                            name="firstName"
                            type="text"
                            placeholder="First Name"
                            {...register("firstName", { required: true })}
                        />
                        {errors.firstName && <div className="errorMessage">First name is required</div>}
                    </div>
                </div>
                <div className="formRow">
                    <label htmlFor="lastName" className="formLabel">Last Name</label>
                    <div>
                        <input className="formValue"
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                            {...register("lastName", { required: true })}
                        />
                        {errors.lastName && <div className="errorMessage">Last name is required</div>}
                    </div>
                </div>
                <div className="formRow">
                    <label htmlFor="dob" className="formLabel">Date of Birth</label>
                    <div>
                        <input className="formValue datePlaceHolder"
                            name="dob"
                            type="date"
                            placeholder="DOB"
                            {...register("dob", { required: true })}
                        />
                        {errors.dob && <div className="errorMessage">Date of birth is required</div>}
                    </div>
                </div>
                <div className="formRow" style={{
                    'marginRight': '8px',
                    'marginLeft': '7px'
                }}>
                    <label htmlFor="country" className="formLabel">
                        Country
                    </label>
                    <div>
                        {/* <input className="formValue"
                            name="country"
                            type="text"
                            placeholder="Country"
                            {...register("country", { required: true })}
                        /> */}
                        <Select
                            className="formSelect"
                            name="country"
                            options={options || []}
                            placeholder="Country"
                            {...register("country", { required: true })}
                            onChange={handleCountryChange}
                        />
                        {errors.country && <div className="errorMessage">Country is required</div>}
                    </div>
                </div>
                <div className="formRow">
                    <label htmlFor="phoneNumber" className="formLabel">
                        Phone Number
                    </label>
                    <div>
                        <input className="formValue"
                            name="phoneNumber"
                            type="text"
                            placeholder="Phone Number"
                            {...register("phoneNumber", {
                                required: true, pattern: {
                                    value: /^\+?\d{10,15}$/,
                                    message: "Entered value does not match phone format"
                                }
                            })}
                        />
                        {errors.phoneNumber && <div className="errorMessage">Phone number is required and needs to be a valid number</div>}
                    </div>
                </div>
                <div className="formRow">
                    <label htmlFor="email" className="formLabel">Email</label>
                    <div>
                        <input className="formValue"
                            name="email"
                            placeholder="Email Address"
                            type="email"
                            {...register("email", {
                                required: true,
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Entered value does not match email format"
                                }
                            })}
                        />
                        {errors.email && (
                            <div className="errorMessage">Email is required and needs to be a valid email</div>
                        )}
                    </div>
                </div>
                <br />
                <div style={{ 'marginTop': '10px', 'marginLeft': '20px', textAlign: "center" }}>
                    <button
                        className="text-applynow-btn"
                        type="submit"
                    >
                        Apply
                    </button>
                </div>
            </form>
        </div >
    );
}

export default ApplicationForm;