import React from "react";
import "../Styles/Footer.css";

function Footer() {
    return (
        <div className="footer-section">
            <div>
                <p style={{ fontWeight: "bold", textDecoration: "underline" }}>Contact Us</p>
                <div>
                    <p>
                        <a href="mailto:support@life-scape.de">support@life-scape.de</a>
                    </p>
                    <p>
                        <a href="tel:+49 176 62340365">+49 176 62340365</a>
                    </p>
                </div>
            </div>
            <div>
                <p>© 2024 Lifescape. All rights reserved.</p>
            </div>

        </div>
    );
}

export default Footer;