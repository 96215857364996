import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/Service.css";

function Services() {
    const navigate = useNavigate();

    const handleApplyNow = () => {
        navigate("/getstarted");
    };
    return (
        <div className="main-section">
            <div className="service-section">
                <h2 className="service-title">
                    <span>Services Offered</span>
                </h2>
                <div className="service-text-step">
                    <div>
                        <FontAwesomeIcon className="fa-icon" icon={faCircleChevronDown} />{" "}Visa application assistance
                    </div>
                    <div>
                        <FontAwesomeIcon className="fa-icon" icon={faCircleChevronDown} />{" "}Career guidance and counselling
                    </div>
                    <div>
                        <FontAwesomeIcon className="fa-icon" icon={faCircleChevronDown} />{" "}Language and cultural support
                    </div>
                    <div>
                        <FontAwesomeIcon className="fa-icon" icon={faCircleChevronDown} />{" "}Job placement assistance
                    </div>
                </div>
            </div>
            <div className="vertical-line"></div>
            <div className="visa-section">
                <h2 className="service-title">
                    <span>Visas We Support</span>
                </h2>
                <div className="visa-text-step">
                    <div>
                        <FontAwesomeIcon className="fa-icon" icon={faCircleChevronDown} />{" "}Work Visa for Nurses after Recognition of Existing Skills
                        <p className="visa-text-description">We apply for recognition of your nursing qualifications in Germany while helping you find the right job for you.</p>
                    </div>
                    <div>
                        <FontAwesomeIcon className="fa-icon" icon={faCircleChevronDown} />{" "}University Nursing Studies
                        <p className="visa-text-description">Explore nursing programs at German universities for further education.</p>
                    </div>
                </div>
                <div style={{ 'marginTop': '10px', 'marginLeft': '20px' }}>
                    <button
                        className="text-applynow-btn"
                        type="button"
                        onClick={handleApplyNow}
                    >
                        Apply Now
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Services;